import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Text = styled.span`
  display: block;
  line-height: 1.2;
  position: relative;
  color: ${({ color }) => (color ? color : 'black')};

  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '400';
      default:
        return '600';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'xlarge':
        return '6rem';
      case 'large':
        return '3.2rem';
      default:
        return '2rem';
    }
  }};

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'xlarge':
          return '4rem';
        case 'large':
          return '2.6rem';
        default:
          return '2rem';
      }
    }};
  `};
`;
