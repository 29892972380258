import React from 'react';
import Layout from 'components/layout';
import Title from 'components/title';
import Box from 'components/box';
import ContactForm from 'components/contactform';

const Contact = ({ data }) => {
  return (
    <Layout>
      {/* <SEO title="Contact" description="Contact description goes here" /> */}
      <Box>
        <Title as="h1" size="large">
          Contact
        </Title>
        <ContactForm />
      </Box>
    </Layout>
  );
};

export default Contact;
